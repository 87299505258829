.MuiInputBase-formControl,
input {
    height: 3rem !important;
}

@media (max-width: 1024px) {
    .MuiInputBase-formControl,
    input {
        height: 2.2rem !important;
    }
}
@media (max-width: 280px) {
    .MuiInputBase-formControl,
    input {
        height: 30px !important;
    }
}
