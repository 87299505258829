.contact-row {
    padding: 3em;
}
.description-contact {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #383f4a;
}
.contact-office-desc {
    margin: 0 5em;
    font-size: 1.1rem;
    color: #383f4a;
}
.contact-office {
    margin: 3em;
    text-align: center;
}
.contactImg {
    border: none;
    margin-top: 0;
}
.contactIconText {
    color: #6415ff;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    margin-top: 0.5rem;
}
#locationMap {
    width: 150%;
    margin-left: -2em;
}
.contact-cover {
    border-radius: 40px;
    height: 100%;
}
.contact-form {
    margin-top: 1.5em;
}
.address1 {
    font-size: 1.2em;
    font-weight: 800;
}
.address2 {
    font-size: 1.2rem;
    color: #383f4a;
}

@media (max-width: 1200px) {
    .description-contact {
        font-size: 1rem;
        line-height: 1.5;
    }
}
@media (max-width: 1024px) {
    .contact-row {
        padding: 1em;
    }
    .contact-cover {
        margin-top: 0;
        border-radius: 20px;
    }
    .prof-service {
        margin-bottom: 2em;
    }
}

@media (max-width: 576px) {
    #locationMap {
        width: 100%;
        margin-left: 0;
    }
    .contact-row {
        padding: 0;
    }
    .contact-office-desc {
        margin: 0;
    }
    .contact-office {
        margin: 0;
    }
    .subDescription {
        height: auto;
    }
    .subDescription {
        margin: 0 auto;
    }
    .contactImg {
        padding: 1em;
    }
    .address2 {
        margin-bottom: 0;
    }
}
@media (max-width: 414px) {
    .prof-service {
        margin-bottom: 17px;
    }
}
@media (max-width: 280px) {
    .contactImg {
        padding: 0;
    }
    .description-contact,
    .contact-office-desc {
        font-size: 12px;
    }
    .contactIconText,
    .address2 {
        font-size: 14px;
    }
}
