.login-logo {
  color: #594ae3;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.auto-margin {
  margin: 0 auto;
}
