@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import "~antd/dist/antd.min.css";
@import "~animate.css/animate.min.css";
@media (orientation: landscape) {
    body {
        flex-direction: row;
    }
}
@media (orientation: portrait) {
    body {
        flex-direction: column;
    }
}
.login-page {
    padding: 5em;
    background: rgb(48 0 141);
    width: 100%;
}
.login-cover {
    background: rgba(250, 245, 255, 1);
}
.login-col {
    background: rgba(247, 250, 252, 1);
    height: 100vh;
}
.login-header-logo {
    margin: 0 auto;
}
.login-header {
    font-size: 2rem;
    text-align: center;
    font-weight: 800;
    line-height: 1.5;
    color: #594ae3;
}
label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.form-custom-label
    span {
    line-height: 2;
    font-size: 14px;
}
.bgHome {
    background-color: #fff !important;
}
.ant-card.ant-card-bordered.applicant-detail .ant-descriptions-title {
    text-align: left;
}
.ant-card.ant-card-bordered.agent-card-desc {
    border-radius: 20px;
    background: #fff;
    margin-top: 2em;
}
.card-div {
    display: flex;
    flex-direction: column;
    border: none !important;
}
.login-subText {
    font-size: 1rem;
    text-align: center;
    margin: 1em;
    color: #637381;
}
.login-img {
    padding: 5em 10em;
    height: 100vh;
}
.login-page-cover {
    padding: 5em;
}
.register {
    padding: 7em 2em;
    padding-bottom: 0;
}

@media (max-width: 576px) {
    .login-img {
        display: none;
    }
    .login-page-cover {
        padding: 13em 2em;
        .navbar-logo {
            width: 100%;
        }
    }
    .login-page {
        padding: 0;
    }
    .drawer-mobile-app
        .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
        background: #fff;
    }
    .card-div {
        width: 100%;
    }
}

@media (max-width: 375px) {
    .login-page-cover {
        padding: 10em 2em;
    }
}
