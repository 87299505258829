.ant-form-horizontal .ant-form-item-label {
    width: 40em;
    text-align: left;
}
form.basicInfoClient-form {
    padding: 3em 1em;
}
.ant-row.ant-form-item.submitBtn {
    padding: 1em 0;
    .ant-form-item-label {
        visibility: hidden;
    }
}
