.footer-container {
    background-color: rgba(26, 32, 44, 1);
    padding: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.img-logo-footer {
    margin-top: -2em;
}
.text-icon {
    width: auto;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.social-icons,
.social-footer {
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}
.social-footer {
    width: auto;
    font-size: 16px;
    font-weight: 600;
    color: rgba(113, 128, 150, 1);
}
.social-text-link {
    margin: 0 10px;
    font-weight: 700;
    color: #fff;
    font-size: 16px;
}
/* Social Icons */
.social-icon-link {
    color: #fff;
    font-size: 24px;
}
@media screen and (max-width: 1024px) {
    .footer-container {
        padding: 4rem;
    }
}
@media screen and (max-width: 820px) {
    .footer-links {
        padding-top: 2rem;
    }

    .footer-input {
        width: 100%;
    }

    .btn {
        width: 100%;
    }

    .footer-link-wrapper {
        flex-direction: column;
    }
}
