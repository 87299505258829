.navbar {
    height: 80px;
    display: block;
    align-items: center;
    font-weight: 500;
    width: 100%;
    top: 0;
    z-index: 999;
    padding: 0 1em;
    position: fixed;
}
// .appointment-btn {
//     line-height: inherit;
//     font-size: 16px;
//     /* padding: 1em; */
//     border-radius: 10px;
//     align-items: center;
//     display: flex;
//     justify-content: center;
//     /* vertical-align: text-top; */
//     height: 40px;
//     margin-top: -7px;
// }
.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    max-width: 100%;
}
a.nav-menus {
    align-items: center;
    color: #243d63;
    padding: 0 15px;
    text-decoration: none;
    font-weight: 600;
}
a.nav-menus.active {
    color: #594ae3;
    text-decoration: underline;
}
a.navBlue {
    color: #fff;
}
.navbar-logo {
    color: #594ae3;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
}
.img-logo {
    width: 5em;
    // height: 4em;
    cursor: pointer;
    border-radius: 10px;
    // margin-top: 1em;
}
.menu-icon {
    display: none;
}
.isMobile {
    display: none !important;
}

.nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    justify-content: end;
    margin: 0;
}

.nav-item {
    height: 80px;
}

.nav-links {
    color: #243d63;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

.nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #594ae3;
}

button.signUp {
    margin-top: -1em;
}

.register-page {
    margin-top: -2em;
}
.register-page-cover {
    padding: 0 5em;
}

@media (max-width: 1200px) {
    .navbar {
        font-size: 1em;
    }
}
@media (max-width: 1024px) {
    .navbar-logo {
        font-size: 1.5rem;
    }
    .register-page-cover {
        padding: 0 3em;
    }
}
@media (max-width: 576px) {
    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        z-index: 1;
        margin-top: -2rem;
    }
    .mobileTop {
        margin-top: -1rem;
    }
    .img-logo {
        width: 5em;
        margin-top: 2em;
    }
    .register-page-cover {
        padding: 13em 2em;
        height: 100vh;
        .navbar-logo {
            width: 100%;
            top: 1em;
        }
    }
    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.6rem;
    }
    .nav-links {
        color: #fff;
    }
    button.ant-btn.ant-btn-round.ant-btn-primary.ant-btn-lg.signUp {
        font-size: small;
    }
    .isMobile {
        display: block !important;
        padding: 0;
        .ant-layout-header {
            padding: 0 !important;
        }
    }
    .coverNav {
        position: inherit;
        background-image: none;
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 95vh;
        position: absolute;
        top: 60px;
        left: -100%;
        grid-gap: 0;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #fff;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        display: flex;
        justify-content: space-evenly;
    }
}
@media (max-width: 540px) {
    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #f8fafc;
        color: #5c15ea;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
    }

    .fa-times {
        color: #594ae3;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #594ae3;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: red;
        transition: 250ms;
    }
}
/* 320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */
