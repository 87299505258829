.ant-form-item {
    margin: 0 0 8px;
}
form#control-hooks input {
    border-radius: 10px;
}
.ant-card-head-title {
    text-align: center;
    text-decoration: underline;
    font-weight: 600;
    font-size: large;
}

@media (max-width: 576px) {
    form#control-hooks .ant-col-sm-6 {
        flex: 40%;
        max-width: 40%;
    }
}
