.ant-tree {
    line-height: 2;
    padding: 30px;
    padding-top: 0;
    margin-top: 2em;
    text-align: left;
    background: #f8fafc;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
    background-color: #f8fafc;
}
.ant-collapse,
.ant-collapse.service-collapse {
    background: transparent;
    color: black;
    font-weight: 700;
    border: none;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: transparent;
    color: #594ae3;
    padding-right: 5px;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
    vertical-align: -webkit-baseline-middle;
}
.services-info h4 {
    color: #534747;
    font-size: 1.2em;
    text-align: left;
}
.services-info .ant-collapse-content-box {
    padding-left: 2.5rem;
    padding-top: 0;
}
.serviceImg {
    border: none;
    margin-top: 1em;
    padding-left: 0;
}
.top-service {
    margin: 3em;
    text-align: center;
}
p.service-desc.service-list {
    margin-left: 4em;
    text-align: left;
    ul {
        list-style-type: disc;
    }
}
.service-bg {
    margin: 0;
    margin-top: 35px;
    height: auto;
    background-color: rgb(89 74 227);
    color: #fff;
    height: 85vh;
    .header-service {
        color: #fff;
        padding-top: 1em;
    }
}
.services-info ul,
.services-info ol {
    padding-left: 3em;
    margin-bottom: 0;
}
.services-info .ant-collapse > .ant-collapse-item {
    margin-top: 0;
}
@media (max-width: 960px) {
    .service-bg {
        height: auto;
    }
}

@media (max-width: 576px) {
    .info-service {
        margin: 0 1em;
    }
    .subDescription {
        height: auto;
    }
    .subDescription {
        margin-bottom: 0;
        padding: 1em;
    }
    .subtext {
        margin-top: 5px;
    }
}
@media (max-width: 375px) {
    .top-service {
        margin-top: 2em;
    }
    .ant-collapse > .ant-collapse-item,
    .ant-collapse-content > .ant-collapse-content-box {
        font-size: 14px;
    }
    .subDescription {
        height: auto;
    }
    .subDescription {
        margin-bottom: 0;
        padding: 1em;
    }
    .subtext {
        margin-top: 10px;
    }
}
@media (max-width: 280px) {
    .ant-collapse > .ant-collapse-item,
    .ant-collapse-content > .ant-collapse-content-box {
        font-size: 12px;
    }
    .subDescription {
        padding: 1em;
    }
}
