input[type="file"]::file-selector-button {
    border: 2px solid #594ae3;
    border-radius: 8px;
    background-color: #594ae3;
    transition: 1s;
    font-weight: 700;
    line-height: 1.5;
    font-size: 0.875rem;
    text-transform: capitalize;
    padding: 4px 14px;
    color: #fff;
} /* error state color */
[data-filepond-item-state*="error"] .filepond--item-panel,
[data-filepond-item-state*="invalid"] .filepond--item-panel {
    background-color: #e31717;
}
.primary-btn {
    background: #594ae3;
    color: white;
}
.uploadStep-notification {
    width: 100%;
    margin-left: 1em;
    border-radius: 10px;
    margin-top: 1em;
}
.visitGrid {
    display: flex;
}
.filepond--file {
    font-weight: 700;
}
[data-filepond-item-state="success"] .filepond--item-panel {
    background-color: green;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiCard-root.stepCard {
    border: none;
    background: #f8fafc;
    width: 80%;
    margin: 0 auto;
    padding: 2em;
    h3 {
        text-align: center;
        text-transform: uppercase;
        font-size: 2em;
    }
}
.ant-card.ant-card-bordered.upload-step-card {
    margin: 2em 0;
    background: #f8fafc;
    border-radius: 20px;
}
a.filepond--credits {
    display: none;
}
.filepond--drop-label {
    background: #e3e3ff;
    color: #000;
}
.file {
    position: relative;
    height: 30px;
    width: 100px;
}
.file > label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #594ae3;
    color: #fff;
    border-radius: 8px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
}

input {
    font-weight: 600;
}
input[type="file"]::file-selector-button:hover {
    background-color: #fff;
    color: #594ae3;
    border: 2px solid #594ae3;
}
@media screen and (max-width: 960px) {
    .ant-btn {
        white-space: normal;
        height: auto !important;
    }
    .ant-alert {
        display: contents;
    }
}
label.mandate {
    color: #594ae3;
}
.mandate:before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1;
    content: "*";
}
